<template>
  <div class="auth-wrapper auth-v2 bg-white">
    <b-row class="auth-inner m-0">

      <!-- Left Text-->
      <b-col lg="6" class="d-none d-lg-flex align-items-center"
        :style="`background-image: url(${sideImg});background-size: cover;background-position: 50%;border-radius: 0px 32px 32px 0px;`">
        <div class="left--side__wrapper p-3">
          <div class="wrapper__side">
            <div>
              <img class="mb-1" src="@/assets/images/logo.png" alt="logo" width="95">
              <hr class="w-25 m-0" style="border: 1px solid #E79242;">
              <div class="my-3">
                <h6 class="size14 fw-bold-400" style="line-height: 23px;">
                  Khusus di desain untuk manajemen Toko Bangunan. <br>
                  Praktis, mudah dan lengkap.
                </h6>
              </div>
            </div>
            <div>
              <h5 class="size14 fw-bold-400 mb-1">
                Sudah punya akun ?
              </h5>
              <h5 class="size16 fw-bold-600" @click="$router.push({ name: 'login' })" role="button">
                Masuk Sekarang
              </h5>
            </div>
          </div>
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="6" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="10" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="fw-bold-800 mb-1 text-dark size20">
            👋🏼 Halo, Selamat Datang
          </b-card-title>
          <b-card-text class="mb-2 text-dark text-darken-4 size14 mb-md-5 mb-0">
            Coba Gratis dan pelajari fitur yang di sediakan
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <!-- hp -->
              <b-form-group class="custom__form--input mb-1">
                <label for="hp">No Hp <span class="text-danger">*</span></label>
                <validation-provider #default="{ errors }" name="No Hp" rules="required">
                  <div class="d-flex align-items-center" style="gap: 0.5rem">
                    <div class="w-50">
                      <b-form-select class="custom__input" v-model="selectedCountryCode">
                        <b-form-select-option :value="item.dial_code" v-for="(item, index) in countryCodes"
                          :key="index">{{ item.dial_code }} ({{ item.name }})</b-form-select-option>
                      </b-form-select>
                      <!-- <v-select v-model="selectedCountryCode" :clearable="false" label="dial_code"
                        :options="country_codes" :reduce="option => option.dial_code"  /> -->
                    </div>
                    <b-form-input id="hp" v-model="handphone" name="login-no-hp" type="number"
                      :state="errors.length > 0 ? false : null" placeholder="Masukan Nomor Handphone"
                      class="custom__input" autofocus style="" @input="removeLeadingZero" />
                  </div>
                  <small class="pt-1">Pastikan nomor yang dimasukan terkoneksi dengan WA</small><br>
                  <small class="text-danger pt-1">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- <b-form-group class="custom__form--input mb-2">
                <label for="hp">No Hp <span class="text-danger">*</span></label>
                <validation-provider #default="{ errors }" name="No Hp" rules="required|regex:^([0-9]+)$">
                  <b-form-input id="hp" v-model="handphone" name="login-no-hp" type="number"
                    :state="errors.length > 0 ? false : null" placeholder="Masukan Nomor Handphone"
                    class="custom__input" autofocus style="height: unset;" onkeydown="return event.keyCode !== 69" />
                  <small class="text-danger pt-1">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group> -->

              <!-- nama -->
              <b-form-group class="custom__form--input mb-2">
                <label for="nama-user">Nama Pengguna <span class="text-danger">*</span></label>
                <validation-provider #default="{ errors }" name="Nama Pengguna" rules="required">
                  <b-form-input id="nama-user" v-model="formPayload.operator_name" name="login-name"
                    class="custom__input" :state="errors.length > 0 ? false : null" placeholder="Masukan Nama"
                    style="height: unset;" />
                  <small class="text-danger pt-1">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- toko -->
              <b-form-group class="custom__form--input mb-2">
                <label for="nama-toko">Nama Toko <span class="text-danger">*</span></label>
                <validation-provider #default="{ errors }" name="Nama Toko" rules="required">
                  <b-form-input id="nama-toko" v-model="formPayload.merchant_name" name="login-name-toko"
                    class="custom__input" :state="errors.length > 0 ? false : null" placeholder="Masukan Nama Toko"
                    style="height: unset;" />
                  <small class="text-danger pt-1">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- toko -->
              <b-form-group class="custom__form--input mb-2">
                <label for="nama-toko">Referral Code </label>
                <validation-provider #default="{ errors }" name="Referral Code">
                  <b-form-input id="nama-toko" v-model="formPayload.referral_code" name="login-name-toko"
                    class="custom__input" :state="errors.length > 0 ? false : null" placeholder="Masukan Referral Code"
                    style="height: unset;" />
                  <small class="text-danger pt-1">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group class="custom__form--input mb-2">
                <label for="login-password">Password <span class="text-danger">*</span></label>
                <validation-provider #default="{ errors }" name="Password" rules="required">
                  <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                    <b-form-input id="password" v-model="formPayload.password" :type="passwordFieldType"
                      class="form-control-merge custom__input" :state="errors.length > 0 ? false : null"
                      name="login-password" placeholder="Password" style="height: unset;" />
                    <b-input-group-append is-text>
                      <feather-icon class="cursor-pointer" :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility" />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Map -->
              <b-form-group class="custom__form--input mb-2">
                <label>Map <span class="text-danger">*</span></label>
                <div style="height: 350px;">
                  <l-map ref="map" :zoom="zoom" :center="[
          position.lat || userLocation.lat || defaultLocation.lat,
          position.lng || userLocation.lng || defaultLocation.lng
        ]" @dblclick="onMapClick">
                    <l-tile-layer :url="tileProvider.url" :attribution="tileProvider.attribution" />
                    <l-marker v-if="position.lat && position.lng" visible draggable :lat-lng.sync="position"
                      @dragstart="dragging = true" @dragend="dragging = false">
                      <l-tooltip :content="tooltipContent" :options="{ permanent: true }" />
                    </l-marker>
                    <v-geosearch :options="geosearchOptions"></v-geosearch>
                  </l-map>
                </div>
                <small class="text-danger" v-if="(!formPayload.address_latitude && !formPayload.address_longitude)">Map
                  wajib diisi</small>
              </b-form-group>

              <b-form-checkbox v-model="terms_condition" :disabled="disabledTerms" class="custom-control-success mb-1">
                Saya setuju dengan <a @click="$bvModal.show('modal-terms-and-condition')" class="text-primary">Syarat
                  dan Ketentuan</a> Toqoo
              </b-form-checkbox>
              <b-form-checkbox v-model="agreement" :disabled="disabledAgreement" class="custom-control-success mb-3">
                Saya setuju dengan <a @click="$bvModal.show('modal-agreement')" class="text-primary">Kesepakatan
                  Bersama</a> Toqoo
              </b-form-checkbox>

              <!-- submit buttons -->
              <b-button id="button--register" type="submit" variant="secondary" block
                :disabled="isLoading || (!terms_condition || !agreement) || (!formPayload.address_latitude && !formPayload.address_longitude)"
                class="size14 py-1 border-8 d-flex justify-content-center align-items-center" @click="validationForm">
                <div v-if="isLoading" class="d-flex justify-content-center mr-1">
                  <b-spinner small label="Loading..." />
                </div>
                Coba Gratis Sekarang
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>Jika ada kendala terkait login bisa </span>
            <a class="text-dark text-darken-6" :href="'https://wa.me/6281807070900'" target="_blank">
              &nbsp;hubungi admin
            </a>
          </b-card-text>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>

    <b-modal id="modal-terms-and-condition" hide-footer body-class="p-0" ok-only scrollable ok-title="Accept" centered
      size="lg" title="Syarat & Ketentuan Penggunaan TOQOO">
      <div id="scroll-terms" class="scroll-terms" @scroll="handleOnScrollTerms">
        <div class="px-2 py-1">
          <app-collapse accordion>
            <app-collapse-item title="Pendahuluan" class="box-shadow-0">
              <p>Syarat dan Ketentuan Penggunaan TOQOO (selanjutnya disebut “Syarat dan Ketentuan”) ini mengatur
                hubungan
                antara pengguna (“Mitra Usaha”) dan PT Dayacipta Solusi Indonesia(“DSI”), dengan ketentuan-ketentuan
                penggunaan sebagaimana tertera di bawah ini yang dituangkan dalam bentuk Kontrak Elektronik.</p>
              <p>DSI dan Mitra Usaha masing-masing disebut sebagai “Pihak”dan secara bersama-sama disebut sebagai “Para
                Pihak”.
                Para Pihak menerangkan terlebih dahulu bahwa:</p>
              <ol>
                <li>DSI adalah suatu perusahaan yang bergerak di bidang aktivitas pengolahan data, portal web dan/atau
                  platform digital dengan tujuan komersial serta penyediaan layanan aplikasi sistem kasir dan
                  pengelolaan
                  stok dengan merek dagang “TOQOO” yang selanjutnya akan disebut sebagai TOQOO.</li>
                <li>Mitra Usaha adalah suatu badan hukum dan/atau perorangan yang bergerak di bidang perdagangan barang
                  dan/atau jasa, bertindak secara independen, menjalankan bisnisnya dengan kebijakan sendiri dan
                  bermaksud
                  untuk menggunakan layanan TOQOO</li>
                <li>Syarat dan Ketentuan Penggunaan ini mengatur akses dan penggunaan Mitra Usaha atas Aplikasi TOQOO
                  dan
                  situs web www.app.toqoo.id termasuk konten dan produk-produk yang TOQOO sediakan didalamnya
                  (selanjutnya
                  secara bersama-sama disebut sebagai “Platform TOQOO”).</li>
                <li>Dengan menyetujui Syarat dan Ketentuan Penggunaan ini, Mitra Usaha juga menyetujui Syarat dan
                  Ketentuan
                  Penggunaan tambahan, termasuk Syarat dan Ketentuan Penggunaan pada masing-masing Layanan, beserta
                  setiap
                  perubahannya yang merupakan bagian yang tidak terpisahkan dari Syarat dan Ketentuan Penggunaan
                  ini(selanjutnya, Syarat dan Ketentuan Penggunaan, Syarat dan Ketentuan Penggunaan tambahan dan setiap
                  perubahannya secara bersama-sama disebut sebagai “Syarat dan Ketentuan Penggunaan”.) Meskipun
                  merupakan
                  satu kesatuan, Syarat dan Ketentuan Penggunaan tambahan akan berlaku dalam hal terdapat perbedaan
                  dengan
                  Syarat dan Ketentuan Penggunaan.</li>
              </ol>
              <p>Sehubungan dengan hal tersebut di atas, Mitra Usaha setuju atas syarat dan ketentuan seperti
                sebagaimana
                tercantum di bawah ini:</p>
            </app-collapse-item>
            <app-collapse-item title="Definisi" class="box-shadow-0">
              <p>Kecuali secara tegas ditentukan lain dalam Syarat dan Ketentuan Penggunaan ini, istilah-istilah yang
                digunakan
                memiliki arti sebagai berikut:</p>

              <ol>
                <li>
                  Afiliasi adalah (i) setiap perusahaan atau badan usaha lainnya yang memiliki kendali atas salah satu
                  Pihak,(ii) setiap perusahaan atau badan usaha lainnya yang mana salah satu Pihak memiliki kendali atas
                  perusahaan atau badan usaha lainnya tersebut, atau (iii) setiap perusahaan atau badan usaha lainnya
                  yang
                  berada di bawah kendali yang sama dengan salah satu Pihak.
                </li>
                <li>Aplikasi TOQOO adalah aplikasi perangkat lunak yang TOQOO kembangkan dari waktu ke waktu yang
                  berfungsi
                  sebagai suatu sarana elektronik untuk membantu Mitra Usaha dalam melaksanakan fungsi kasir digital dan
                  pengelolaan stok toko melalui perangkat PC dan/atau tablet.</li>
                <li>Dokumen Administratif adalah dokumen yang menunjukkan legalitas Mitra Usaha.</li>
                <li>Formulir Mitra Usaha adalah formulir pendaftaran Layanan dan/atau formulir-formulir lainnya dalam
                  bentuk
                  hardcopy atau elektronik, yang berisi mengenai keterangan data dan informasi Mitra Usaha, termasuk
                  namun
                  tidak terbatas pada nama, alamat dan nomor telepon Mitra Usaha yang merupakan satu kesatuan yang tidak
                  terpisahkan dengan Syarat dan Ketentuan Penggunaan ini.</li>
                <li>Toko adalah unit usaha yang dimiliki, dikelola dan didaftarkan oleh Mitra Usaha di dalam Platform
                  TOQOO
                  sebagaimana dapat diubah dan ditambah dari waktu ke waktu oleh Mitra Usaha.</li>
                <li>Hak Kekayaan Intelektual adalah hak eksklusif yang diberikan atas suatu karya cipta berupa hak
                  paten,
                  merek dagang, hak cipta (termasuk hak dalam perangkat lunak), nama dagang, nama domain internet,
                  topografi, hak desain, hak moral, hak-hak dalam data basis, rahasia dagang, cara penggunaan dan
                  Informasi
                  Rahasia lainnya, ilmu pengetahuan (know-how), ciptaan, kode piranti lunak dan hak-hak kekayaan
                  intelektual
                  lainnya, baik terdaftar maupun tidak terdaftar, dan termasuk sedang dalam aplikasi untuk pendaftaran,
                  dan
                  seluruh hak atau bentuk lain dari perlindungan yang memiliki efek yang serupa dimanapun di dunia ini
                  yang
                  daripadanya merupakan Hak Kekayaan Intelektual.</li>
                <li>
                  Informasi Rahasia adalah data dan/atau informasi yang:
                  <ul>
                    <li>diberikan oleh salah satu Pihak kepada Pihak lainnya, sehubungan dengan pelaksanaan Layanan oleh
                      TOQOO kepada Mitra Usaha;</li>
                    <li>merupakan hak milik dari, mengenai atau dibuat oleh salah satu Pihak; dan</li>
                    <li>mengenai salah satu Pihak yang memberikan Pihak yang lain suatu manfaat bisnis atau kesempatan
                      untuk
                      memperoleh manfaat tersebut atau pengungkapan dari hal mana dapat merugikan kepentingan Pihak
                      tersebut
                    </li>
                  </ul>
                </li>
                <li>Kebijakan Privasi adalah kebijakan privasi atas penggunaan Layanan yang dapat diakses pada
                  www.toqoo.id/privacy maupun melalui Aplikasi, sebagaimana diterapkan serta dapat diubah atau
                  ditambahkan
                  dari waktu ke waktu oleh TOQOO yang merupakan bagian dan satu kesatuan yang tidak terpisahkan dari
                  Syarat
                  dan Ketentuan Penggunaan ini.</li>
                <li>Kontrak Elektronik adalah perjanjian Para Pihak yang dibuat melalui sistem elektronik sebagaimana
                  diatur
                  dalam Undang-Undang No. 11 Tahun 2008 tentang Informasi dan Transaksi Elektronik sebagaimana telah
                  diubah
                  dengan Undang-Undang No. 19 Tahun 2016 tentang Perubahan Atas Undang-Undang No. 11 Tahun 2008 tentang
                  Informasi dan Transaksi Elektronik dan Peraturan Pemerintah No. 71 Tahun 2019 tentang Penyelenggaraan
                  Sistem dan Transaksi Elektronik.</li>
                <li>Layanan adalah layanan TOQOO dan/atau layanan lainnya yang TOQOO sediakan dan kembangkan dari waktu
                  ke
                  waktu.</li>
                <li>Mitra Pihak Ketiga Penyedia Layanan adalah pihak ketiga yang memiliki hubungan kerja sama dengan
                  TOQOO,
                  termasuk namun tidak terbatas pada pihak ketiga yang bekerja sama dengan DSI untuk menyelenggarakan
                  Layanan.</li>
                <li>Pelanggan adalah konsumen Mitra Usaha yang melakukan Transaksi.</li>
                <li>Transaksi adalah setiap transaksi jual beli barang dan/atau jasa yang diproses menggunakan dan
                  melalui
                  Layanan.</li>
              </ol>
            </app-collapse-item>
            <app-collapse-item title="Informasi Pribadi" class="box-shadow-0">
              <ol>
                <li>
                  Pengumpulan, penyimpanan, pengolahan, penggunaan dan pembagian informasi pribadi Mitra Usaha yang
                  Mitra
                  Usaha berikan ketika melakukan aktivasi Akun akan tunduk pada kebijakan privasi, yang merupakan bagian
                  yang tidak terpisahkan dari Syarat dan Ketentuan Penggunaan ini. Yang dimaksud dengan informasi
                  pribadi,
                  merujuk kepada informasi pribadi yang didefinisikan berdasarkan kebijakan privasi. </li>
                <li>
                  Mitra Usaha memahami dan setuju bahwa ketika Mitra Usaha melakukan aktivasi Akun, mengakses dan/atau
                  menggunakan Platform TOQOO dan/atau Layanan, data dan informasi pribadi Mitra Usaha yang diberikan
                  kepada
                  TOQOO, dapat termasuk namun tidak terbatas pada, informasi pribadi Pelanggan Mitra Usaha yang
                  dimasukkan
                  secara langsung oleh Mitra Usaha pada Platform TOQOO, dapat dibagikan kepada Afiliasi TOQOO dan/atau
                  Mitra
                  Pihak Ketiga Penyedia Layanan untuk mendukung Layanan TOQOO kepada Mitra Usaha. Mitra Usaha memahami
                  bahwa
                  pengiriman data ini diperlukan untuk mendukung dan meningkatkan pengalaman Mitra Usaha dalam
                  menggunakan
                  Platform TOQOO dan/atau Layanan dan dengan ini Mitra Usaha menyetujui pembagian data tersebut. TOQOO
                  mendorong Mitra Usaha untuk membaca dan meninjau kebijakan privasi yang relevan secara menyeluruh.
                  Dalam
                  hal Mitra Usaha memasukkan informasi pribadi Pelanggan pada Platform TOQOO, Mitra Usaha menjamin bahwa
                  Mitra Usaha telah mendapatkan persetujuan yang layak dari Pelanggan berdasarkan ketentuan
                  perundang-undangan yang berlaku.
                </li>
                <li>Mitra Usaha bertanggung jawab sepenuhnya atas kebenaran data dan/atau keterangan dan/atau dokumen
                  lainnya yang diserahkan oleh Mitra Usaha kepada TOQOO dan Mitra Usaha dengan ini membebaskan TOQOO
                  dari
                  segala klaim, gugatan dan/atau tuntutan hukum dalam bentuk apapun dan dari pihak manapun termasuk dari
                  Mitra Usaha sehubungan dengan setiap informasi dan/atau data yang disampaikan oleh Mitra Usaha kepada
                  TOQOO.
                </li>
                <li>TOQOO tidak akan mengungkapkan informasi pribadi Mitra Usaha kepada pihak manapun, kecuali :
                  <br>
                  <ul>
                    <li>sebagaimana yang diizinkan berdasarkan Syarat dan Ketentuan Penggunaan ini dan/atau kebijakan
                      privasi;</li>
                    <li>pemberian informasi berdasarkan perintah dari pengadilan yang berwenang atau oleh badan atau
                      komite
                      judisial atau pemerintahan atau badan legislatif; atau</li>
                    <li>bila diharuskan oleh ketentuan hukum yang berlaku</li>
                  </ul>
                </li>
                <li>Mitra
                  Usaha memahami bahwa Mitra Usaha dapat melakukan akses terhadap informasi pribadi Pelanggan yang
                  tersedia di Platform TOQOO. Dalam hal Mitra Usaha melakukan tindakan-tindakan, termasuk namun tidak
                  terbatas pada, pengaksesan, penyimpanan, penggunaan, dan/atau pengungkapan informasi pribadi Pelanggan
                  untuk kepentingan Mitra Usaha di luar penyelesaian pesanan Pelanggan, maka Mitra Usaha bertanggung
                  jawab
                  atas segala tindakan yang dilakukan oleh Mitra Usaha terhadap informasi pribadi Pelanggan dan
                  melepaskan
                  TOQOO dari segala klaim, tuntutan dan/atau ganti rugi sehubungan dengan tindakan yang dilakukan
                  terhadap
                  informasi pribadi Pelanggan oleh Mitra Usaha.</li>
              </ol>
            </app-collapse-item>
            <app-collapse-item title="Hak Kekayaan Intelektual" class="box-shadow-0">
              <ol>
                <li>Platform TOQOO dan Layanan, termasuk namun tidak terbatas pada, nama, logo, kode program, desain,
                  merek
                  dagang, teknologi, basis data, proses dan model bisnis, dilindungi oleh hak cipta, merek, paten dan
                  Hak
                  Kekayaan Intelektual lainnya yang tersedia berdasarkan hukum Republik Indonesia yang terdaftar baik
                  atas
                  nama TOQOO ataupun Afiliasi TOQOO. TOQOO (dan pemberi lisensi TOQOO) memiliki seluruh hak dan
                  kepentingan
                  atas Platform TOQOO dan Layanan, termasuk seluruh Hak Kekayaan Intelektual terkait dengan seluruh
                  fitur
                  yang terdapat didalamnya dan Hak Kekayaan Intelektual terkait.</li>
                <li>Tunduk pada Syarat dan Ketentuan Penggunaan ini, TOQOO memberikan Mitra Usaha lisensi terbatas yang
                  tidak eksklusif, dapat ditarik kembali, tidak dapat dialihkan(tanpa hak sublisensi) untuk mengunduh
                  dan/atau mengakses serta menggunakan Platform TOQOO, sebagaimana adanya, terbatas hanya untuk
                  keperluan
                  penggunaan Platform TOQOO dan Layanan baik melalui aplikasi maupun web-dashboard. Hak istimewa lainnya
                  yang tidak secara tegas diberikan dalam Syarat dan Ketentuan Penggunaan ini, adalah hak TOQOO atau
                  pemberi
                  lisensi TOQOO.</li>
                <li>TOQOO diberikan izin untuk menggunakan nama, logo, lambang, simbol, merek dagang milik Mitra Usaha
                  untuk
                  tujuan promosi atau publikasi terkait penggunaan Platform TOQOO dan Layanan, tanpa adanya pengalihan
                  Hak
                  Kekayaan Intelektual dari Mitra Usaha kepada TOQOO.</li>
                <li>Setiap penggandaan, distribusi, pembuatan karya turunan, penjualan atau penawaran untuk menjual,
                  penampilan baik sebagian atau seluruhnya, serta penggunaan Platform TOQOO dan/atau Layanan, baik
                  secara
                  digital atau lainnya, termasuk namun tidak terbatas pada penggunaan aplikasi tambahan seperti aplikasi
                  modifikasi, emulator, dan lain-lain, yang menyimpang dari Syarat dan Ketentuan Penggunaan ini, maupun
                  tujuan penggunaan yang ditentukan oleh TOQOO, merupakan pelanggaran terhadap Hak Kekayaan Intelektual
                  TOQOO.</li>
                <li>Mitra Usaha tidak boleh:
                  <br>
                  <ul>
                    <li>Menghapus setiap pemberitahuan hak cipta, merek dagang atau pemberitahuan hak milik lainnya yang
                      terkandung dalam Platform TOQOO;</li>
                    <li>menyalin, memodifikasi, mengadaptasi, menerjemahkan, membuat karya turunan dari,
                      mendistribusikan,
                      memberikan lisensi, menjual, mengalihkan, menampilkan di muka umum baik sebagian maupun
                      seluruhnya,
                      merekayasa balik (reverse engineer), mentransmisikan, memindahkan, menyiarkan, menguraikan, atau
                      membongkar bagian manapun dari atau dengan cara lain mengeksploitasi Platform TOQOO(termasuk
                      perangkat
                      lunak, fitur dan Layanan di dalamnya);</li>
                    <li>memberikan lisensi, mensublisensikan, menjual, menjual kembali, memindahkan, mengalihkan,
                      mendistribusikan atau mengeksploitasi secara komersial atau membuat tersedia kepada pihak lain
                      Platform TOQOO dan/atau perangkat lunak dengan cara menciptakan tautan (link) internet ke Platform
                      TOQOO atau “frame” atau “mirror” setiap perangkat lunak pada server lain atau perangkat nirkabel
                      atau
                      yang berbasis internet;</li>
                    <li>meluncurkan program otomatis atau script, termasuk, namun tidak terbatas pada, web spiders, web
                      crawlers, web robots, web ants, web indexers, bots, virus atau worm, atau program apapun yang
                      mungkin
                      membuat beberapa permintaan server per detik, menciptakan beban berat atau menghambat operasi
                      dan/atau
                      kinerja Platform TOQOO;</li>
                    <li>menggunakan aplikasi pencarian atau pengambilan kembali situs, perangkat manual atau otomatis
                      lainnya untuk mengambil (scraping), indeks(indexing), survei (surveying), tambang data (data
                      mining),
                      atau dengan cara apapun memperbanyak atau menghindari struktur navigasi atau presentasi dari
                      Platform
                      TOQOO atau isinya;</li>
                    <li>menerbitkan, mendistribusikan atau memperbanyak dengan cara apapun materi yang dilindungi hak
                      cipta,
                      merek dagang, atau informasi lain yang dimiliki oleh TOQOO tanpa persetujuan tertulis terlebih
                      dahulu
                      dari TOQOO atau pemilik hak yang melisensikan hak-nya kepada TOQOO; dan</li>
                    <li>menggunakan dan/atau mengakses secara tidak resmi Platform TOQOO untuk (i) merusak, melemahkan
                      atau
                      membahayakan setiap aspek dari Platform TOQOO, Layanan atau sistem dan jaringan terkait;
                      dan/atau(ii)
                      membuat produk atau layanan tandingan serupa menggunakan ide, fitur, fungsi atau grafik menyerupai
                      Platform TOQOO.</li>
                  </ul>
                </li>
              </ol>
            </app-collapse-item>
            <app-collapse-item title="Kewenangan Mutlak TOQOO" class="box-shadow-0">
              <p>TOQOO berhak membekukan Akun, baik sementara atau permanen, atau menghentikan akses Mitra Usaha
                terhadap
                Platform TOQOO, termasuk Layanan dan/atau Penawaran yang terdapat di dalamnya, melakukan pemeriksaan,
                menuntut ganti kerugian, melaporkan kepada pihak berwenang, melalukan tindakan hukum baik secara Pidana
                maupun Perdata, dan/atau mengambil tindakan lain yang TOQOO anggap perlu, apabila terdapat dugaan bahwa
                Mitra Usaha melakukan pelanggaran, kejahatan atau tindakan lain yang bertentangan dengan peraturan
                perundang-undangan yang berlaku dan/atau terhadap Syarat dan Ketentuan Penggunaan ini.</p>
            </app-collapse-item>
            <app-collapse-item title="Pernyataan dan Jaminan Mitra Usaha" class="box-shadow-0">
              <ol>
                <li>Mitra Usaha menjamin kepada TOQOO bahwa jika Mitra Usaha adalah (i) orang perorangan maka Mitra
                  Usaha
                  adalah orang perorangan yang berwenang untuk melakukan tindakan hukum berdasarkan peraturan
                  perundang-undangan yang berlaku di Negara Republik Indonesia dan tidak sedang dalam pengampuan; atau
                  (ii)
                  badan hukum maka Mitra Usaha adalah badan hukum yang didirikan berdasarkan hukum dan peraturan
                  perundang-undangan yang berlaku di Negara Republik Indonesia.</li>
                <li>Mitra Usaha dengan ini menyatakan dan menjamin bahwa seluruh data, informasi pribadi dan/atau konten
                  yang dimasukkan ke dalam Platform TOQOO adalah data, informasi pribadi dan/atau konten yang sepenuhnya
                  dimiliki oleh Mitra Usaha, termasuk namun tidak terbatas pada informasi pribadi Pelanggan yang
                  dimasukkan
                  oleh Mitra Usaha ke dalam Platform TOQOO. Mitra Usaha lebih lanjut menjamin kepada TOQOO bahwa Mitra
                  Usaha
                  telah mendapatkan persetujuan dari Pelanggan ataupun pemilik data atau informasi untuk bisa
                  menggunakan
                  dan/atau mengolah informasi pribadi Pelanggan, termasuk untuk keperluan penggunaan Layanan yang
                  terdapat
                  di dalam Platform TOQOO.</li>
                <li>Mitra Usaha dengan ini menyatakan dan menjamin akan mematuhi seluruh kebijakan Know Your Business
                  (KYB)
                  yang diadopsi oleh TOQOO sesuai dengan Syarat dan Ketentuan Penggunaan ini, Ketentuan Penggunaan
                  Khusus
                  dan/atau hukum serta peraturan yang berlaku, termasuk namun tidak terbatas pada mengumpulkan seluruh
                  dokumentasi dan informasi lainnya yang diperlukan dari Mitra Usaha.</li>
                <li>Mitra Usaha dengan ini menyatakan dan menjamin bahwa pelaksanaan hak dan/atau kewajiban yang
                  terdapat
                  di
                  dalam Syarat dan Ketentuan Penggunaan ini tidak akan melanggar: (i) ketentuan anggaran dasar Mitra
                  Usaha(dalam hal Mitra Usaha adalah badan hukum), (ii)perjanjian apapun di mana Mitra Usaha terikat
                  menjadi
                  pihak di dalamnya, dan (iii) peraturan perundang-undangan yang berlaku.</li>
                <li>Dalam menggunakan Platform TOQOO dan Layanan, Mitra Usaha wajib untuk memiliki seluruh lisensi dan
                  perizinan yang diperlukan untuk melakukan kegiatan usaha penyediaan barang dan/atau jasa sesuai dengan
                  bidang usaha Mitra Usaha dan Mitra Usaha berjanji akan menaati hukum dan perundang-undangan yang
                  berlaku,
                  termasuk namun tidak terbatas kepada setiap peraturan perundang-undang anti korupsi, anti suap dan
                  anti
                  pencucian uang yang berlaku di Negara Republik Indonesia.</li>
                <li>Dalam melaksanakan Syarat dan Ketentuan Penggunaan ini, Mitra Usaha menjamin untuk tidak menawarkan,
                  menjanjikan, menyetujui dan mengesahkan setiap pembayaran atau pemberian, baik secara langsung maupun
                  tidak langsung, barang atau materi yang memiliki nilai(termasuk namun tidak terbatas pada hadiah,
                  hiburan,
                  makanan, diskon atau kredit pribadi, atau manfaat lainnya yang tidak dibayarkan pada nilai pasar) yang
                  mempunyai tujuan atau efek penyuapan dan korupsi yang berlaku di Indonesia atau peraturan dan hukum
                  yang
                  melarang setiap tindakan yang melanggar hukum untuk tujuan mendapatkan manfaat komersial bisnis.</li>
                <li>Mitra Usaha dengan ini menyatakan dan menjamin bahwa Mitra Usaha tidak menyediakan konten, barang
                  dan/atau jasa yang merupakan bagian ataupun dicurigai dan/atau sedang dalam proses hukum, namun tidak
                  terbatas sebagai berikut:
                  <br>
                  <ul>
                    <li>Barang dan/atau jasa ilegal ataupun yang mengandung materi ilegal;</li>
                    <li>Terdiri dari barang dan/atau jasa yang melanggar hak kekayaan intelektual atau hak kepemilikan
                      lainnya dari pihak ketiga;</li>
                    <li>Memfasilitasi kegiatan yang ilegal, melanggar hukum, atau tindakan pidana lainnya;</li>
                    <li>Mencantumkan kalimat penjelasan yang ambigu dan/atau tidak jelas, informasi menyesatkan serta
                      konten
                      dan informasi yang melanggar hukum dan peraturan yang berlaku;</li>
                    <li>Berpotensi menimbulkan konflik yang berkaitan dengan etnis, agama, ras dan kelompok;</li>
                    <li>Mengandung pornografi atau hal yang melanggar norma-norma etika; dan/atau</li>
                    <li>Mengandung penghinaan, pemerasan dan/atau konten pemfitnahan.</li>
                  </ul>
                </li>
                <li>Dengan melanjutkan akses atau penggunaan terhadap Platform TOQOO dan/atau Layanan, Mitra Usaha
                  setuju
                  untuk tunduk dan mematuhi semua ketentuan peraturan perundang-undangan terkait dan Syarat dan
                  Ketentuan
                  Penggunaan ini, termasuk semua perubahannya dan syarat dan ketentuan dan kebijakan privasi yang
                  ditentukan
                  oleh Mitra Pihak Ketiga Penyedia Jasa, dan Penyedia Penawaran. Segera hentikan akses atau penggunaan
                  Platform TOQOO dan/atau Layanan jika Mitra Usaha tidak setuju dengan Pasal apapun dari Syarat dan
                  Ketentuan Penggunaan ini.</li>
                <li>Mitra Usaha dengan ini menyatakan dan menjamin tidak akan mengalihkan hak dan kewajiban Mitra Usaha,
                  baik sebagian maupun keseluruhan berdasarkan Syarat dan Ketentuan Penggunaan ini, tanpa persetujuan
                  terlebih dahulu dari TOQOO. Namun, TOQOO dapat mengalihkan hak TOQOO berdasarkan Syarat dan Ketentuan
                  Penggunaan ini setiap saat kepada Afiliasi TOQOO dan/atau pihak lain yang TOQOO tunjuk tanpa perlu
                  mendapatkan persetujuan terlebih dahulu dari Mitra Usaha atau memberikan pemberitahuan sebelumnya
                  kepada
                  Mitra Usaha.</li>
                <li>Dalam hal Mitra Usaha melakukan pelanggaran atas ketentuan Pasal ini, maka TOQOO berhak untuk
                  (i)melakukan tindakan penghapusan, pembatalan dan/atau pemblokiran terhadap konten yang dilarang;
                  (ii)menghentikan akses Mitra Usaha terhadap Platform TOQOO dan/atau Layanan; dan/atau (iii) mengakhiri
                  Syarat dan Syarat dan Ketentuan Penggunaan ini dengan segera secara sepihak, tanpa pemberitahuan
                  terlebih
                  dahulu kepada Mitra Usaha.</li>
              </ol>
            </app-collapse-item>
            <app-collapse-item title="Ganti Rugi dan Pembatasan Tanggung Jawab" class="box-shadow-0">
              <ol>
                <li>Mitra Usaha dengan ini setuju untuk mengganti kerugian dan membebaskan TOQOO dan/atau Afiliasi TOQOO
                  dari setiap dan seluruh klaim, kewajiban, atau tuntutan kerugian terhadap, atau diancam untuk dibawa
                  terhadap TOQOO dan/atau Afiliasi TOQOO, oleh pihak manapun, termasuk namun tidak terbatas pada hal-hal
                  yang berkaitan dengan :
                  <br>
                  <ul>
                    <li>tindakan penipuan, kriminal atau tindakan tidak sah yang dilakukan Mitra Usaha atau karyawan
                      Mitra
                      Usaha;</li>
                    <li>setiap akses pihak ketiga yang tidak sah atau ilegal terhadap Informasi Rahasia yang diakibatkan
                      oleh kesalahan dan/atau kelalaian Mitra Usaha;</li>
                    <li>setiap tindakan kelalaian atau kesalahan oleh Mitra Usaha dan/atau karyawan Mitra Usaha;</li>
                    <li>pelanggaran Mitra Usaha terhadap Syarat dan Ketentuan Penggunaan ini maupun sehubungan dengan
                      Layanan, atau Penyedia Penawaran;</li>
                    <li>setiap pelanggaran atas ketentuan manajemen konten yang disebabkan oleh tindakan kelalaian atau
                      kesalahan dari Mitra Usaha dan/atau karyawan Mitra Usaha; dan</li>
                    <li>setiap pelanggaran terhadap ketentuan perlindungan data pribadi yang berlaku sehubungan dengan
                      penggunaan informasi pribadi Pelanggan oleh Mitra Usaha; dan</li>
                    <li>setiap hal sehubungan dengan kerugian yang diderita oleh Pelanggan.</li>
                  </ul>
                </li>
                <li>Kewajiban TOQOO atas setiap klaim yang diajukan sehubungan dengan pemberian Layanan tidak akan
                  melebihi
                  jumlah yang setara dengan keseluruhan biaya yang dibayarkan oleh Mitra Usaha kepada TOQOO sehubungan
                  dengan pemberian Layanan dalam periode 1 (satu) tahun sebelum terjadinya pelanggaran.</li>
                <li>Mitra Usaha sepenuhnya bertanggung jawab atas Informasi Rahasia karyawan Mitra Usaha dan Informasi
                  Rahasia Pelanggan yang diterima oleh Mitra Usaha. Mitra Usaha setuju untuk melepaskan TOQOO dan/atau
                  Afiliasi TOQOO dari klaim apapun yang timbul sehubungan dengan penggunaan Informasi Rahasia karyawan
                  dan/atau Informasi Rahasia Pelanggan baik oleh Mitra Usaha dan/atau pihak ketiga lainnya. Syarat dan
                  Ketentuan dalam Pasal ini akan terus berlaku kepada Para Pihak baik setelah berakhirnya Syarat dan
                  Ketentuan Penggunaan ini.</li>
                <li>Mitra Usaha dengan ini setuju untuk melepaskan TOQOO dari klaim apapun yang timbul sehubungan dengan
                  virus, kerusakan, gangguan, atau bentuk lain dari gangguan sistem, termasuk klaim sehubungan dengan
                  akses
                  tanpa otorisasi oleh pihak ketiga dan/atau klaim sebagai akibat dari penggunaan perangkat keras
                  dan/atau
                  jaringan yang tidak sesuai dengan rekomendasi TOQOO. Mitra Usaha wajib untuk memberitahu TOQOO
                  sesegera
                  mungkin jika Mitra Usaha mengalami gangguan sistem apapun sebagaimana disebutkan di atas sehingga
                  TOQOO
                  dapat berusaha memperbaiki gangguan tersebut.</li>
                <li>Masing-masing Pihak tidak bertanggung jawab kepada Pihak yang lainnya atas kerugian baik langsung
                  maupun
                  tidak langsung, konsekuensial, insidental atau hilangnya data, peluang pendapatan, keuntungan,
                  gangguan
                  usaha atau biaya asuransi terlepas dari apakah kerugian tersebut dapat Pihak tersebut duga atau
                  perkirakan
                  atau Pihak tersebut telah diberitahu akan kemungkinan kerugian tersebut.</li>
              </ol>
            </app-collapse-item>
            <app-collapse-item title="Kerahasiaan" class="box-shadow-0">
              <ol>
                <li>Mitra Usaha sepakat bahwa pertukaran informasi yang muncul karena Syarat dan Ketentuan Penggunaan
                  ini
                  dikategorikan sebagai Informasi Rahasia dan untuk itu Mitra Usaha sepakat untuk saling menjaga
                  kerahasiaan
                  informasi tersebut kecuali telah mendapatkan izin tertulis dari TOQOO dan/atau informasi tersebut
                  telah
                  berlaku dan diketahui secara umum atau telah disepakati dalam Syarat dan Ketentuan Penggunaan ini.
                </li>
                <li>Mitra Usaha sepakat untuk tidak mengungkapkan Informasi Rahasia yang Mitra Usaha terima kepada pihak
                  ketiga lainnya tanpa memperoleh terlebih dahulu persetujuan dari TOQOO dan akan mengambil
                  langkah-langkah
                  yang dinilai wajar guna mencegah pengungkapan Informasi Rahasia tersebut. Mitra Usaha sepakat untuk
                  tidak
                  menggunakan, menggandakan atau mengalihkan Informasi Rahasia milik TOQOO selain daripada yang
                  diperlukan
                  dalam melaksanakan kewajibannya dalam Syarat dan Ketentuan Penggunaan ini, tanpa memperoleh terlebih
                  dahulu persetujuan dari TOQOO, dan akan melakukan tindakan-tindakan pencegahan yang dinilai wajar guna
                  mencegah terjadinya penggunaan, penggandaan atau pengalihan atas Informasi Rahasia tersebut.</li>
                <li>Mitra Usaha sepakat bahwa akses terhadap Informasi Rahasia yang diberikan TOQOO hanya akan diberikan
                  kepada direktur, karyawan, akuntan dan/atau penasehat Mitra Usaha yang memerlukan akses terhadap
                  Informasi
                  Rahasia untuk kepentingan pelaksanaan Syarat dan Ketentuan Penggunaan ini dan dengan ketentuan bahwa
                  Mitra
                  Usaha bertanggung jawab atas penggunaan Informasi Rahasia oleh pihak-pihak tersebut di atas.</li>
                <li>Mitra Usaha wajib untuk menjaga kerahasiaan Informasi Rahasia dan sepakat untuk tidak memberitahukan
                  dan/atau memberikan Informasi Rahasia, baik sebagian ataupun seluruhnya, kepada pihak ketiga manapun
                  juga,
                  selain daripada yang diperbolehkan berdasarkan Syarat dan Ketentuan Penggunaan ini. Untuk menghindari
                  keraguan, ketentuan kerahasiaan sebagaimana diatur dalam Pasal ini akan berlaku dan mengikat sejak
                  Syarat
                  dan Ketentuan Penggunaan ini berlaku efektif dan akan tetap bertahan dan berlaku sampai dengan 5
                  (lima)
                  tahun setelah penggunaan Mitra Usaha atas Platform TOQOO berakhir.</li>
                <li>Mitra Usaha sepakat bahwa TOQOO berhak untuk (1)memberitahukan segala data dan Informasi Rahasia
                  kepada
                  Afiliasi, vendor, subkontraktor, agen, konsultan semata-mata untuk keperluan pelaksanaan Syarat dan
                  Ketentuan Penggunaan ini; dan/atau (2) mengolah dan menggunakan data Mitra Usaha untuk (a)
                  meningkatkan
                  layanan TOQOO dan/atau Afiliasinya dan/atau Mitra Pihak Ketiga Penyedia Layanan kepada Mitra Usaha
                  (termasuk untuk sistem fraud, fraud rules dan keperluan audit korporasi); serta (b) untuk melakukan
                  penawaran produk dan/atau layanan TOQOO dan/atau Afiliasinya dan/atau Mitra Pihak Ketiga Penyedia
                  Layanan
                  lainnya kepada Mitra Usaha.</li>
                <li>TOQOO berhak untuk mengungkapkan Informasi Rahasia jika diwajibkan atau diminta atas perintah
                  pengadilan
                  atau oleh otoritas pemerintah manapun.</li>
              </ol>
            </app-collapse-item>
            <app-collapse-item title="Tanggung Jawab Mitra Usaha" class="box-shadow-0">
              <ol>
                <li>Mitra Usaha bertanggung jawab penuh atas keputusan yang Mitra Usaha buat untuk menggunakan atau
                  mengakses Platform TOQOO, Layanan dan Penawaran. Mitra Usaha bertanggung jawab secara penuh atas
                  setiap
                  kerugian dan/atau klaim yang timbul dari penggunaan Platform TOQOO, Layanan dan/atau Penawaran melalui
                  Akun, baik oleh Mitra Usaha atau pihak ketiga lainnya yang Mitra Usaha otorisasi untuk menggunakan
                  Akun,
                  dengan cara yang bertentangan dengan Syarat dan Ketentuan Penggunaan, kebijakan privasi, Syarat dan
                  Ketentuan Penggunaan tambahan, termasuk syarat dan ketentuan dan kebijakan privasi yang ditentukan
                  Mitra
                  Pihak Ketiga Penyedia Layanan, Penyedia Penawaran dan peraturan perundang-undangan yang berlaku,
                  termasuk
                  namun tidak terbatas untuk tujuan anti pencucian uang, anti pendanaan terorisme, aktivitas kriminal,
                  penipuan dalam bentuk apapun (termasuk namun tidak terbatas pada kegiatan phishing dan/atau social
                  engineering), pelanggaran Hak Kekayaan Intelektual, dan/atau aktivitas lain yang merugikan publik
                  dan/atau
                  pihak lain manapun atau yang dapat atau dianggap dapat merusak reputasi TOQOO.</li>
                <li>Mitra Usaha memahami dan menyetujui bahwa TOQOO dapat meminta tambahan Dokumen Administratif selama
                  Mitra Usaha menggunakan Platform TOQOO dan Layanan. Mitra Usaha wajib untuk memberikan Dokumen
                  Administratif tersebut dengan cara mengunggahnya ke dalam Platform TOQOO dan/atau dengan cara lain
                  yang
                  diinformasikan oleh TOQOO.</li>
                <li>Dalam menggunakan Platform TOQOO dan Layanan, Mitra Usaha wajib untuk memiliki seluruh lisensi dan
                  perizinan yang diperlukan untuk terlibat dalam kegiatan usaha penyediaan barang dan/atau jasa sesuai
                  dengan bidang usaha Mitra Usaha. Mitra Usaha lebih lanjut bertanggung jawab penuh apabila dikemudian
                  hari
                  kegiatan usaha Mitra Usaha dinyatakan melanggar hukum, peraturan perundang-undangan dan ketertiban
                  umum.
                </li>
                <li>Mitra Usaha bertanggung jawab sepenuhnya atas penggunaan Platform TOQOO dan Layanan dalam cara yang
                  wajar dan setuju untuk tidak meminta pertanggungjawaban dari TOQOO atas segala tuntutan, kerugian,
                  dan/atau kehilangan sehubungan dengan penggunaan Platform TOQOO dan Layanan dalam cara yang tidak
                  wajar
                  atau tidak sesuai dengan Syarat dan Ketentuan Penggunaan ini dan peraturan perundang-undangan yang
                  berlaku. Mitra Usaha setuju bahwa Mitra Usaha tidak akan mengaitkan Platform TOQOO dan Layanan dengan
                  aktivitas apapun yang mungkin dianggap, termasuk namun tidak terbatas, sebagai:
                  <br>
                  <ul>
                    <li>Melanggar Hak Kekayaan Intelektual pihak ketiga manapun;</li>
                    <li>Melanggar hak publikasi atau hak privasi pihak ketiga manapun;</li>
                    <li>Ilegal atau mendorong atau sehubungan dengan, baik secara langsung maupun tidak langsung,
                      tindakan
                      ilegal apapun;</li>
                    <li>Menjual penggunaan, akses, atau mereproduksi Platform TOQOO dan Layanan kepada pihak ketiga
                      manapun;
                      dan/atau</li>
                    <li>Suatu penipuan, memperdaya, atau menyesatkan dengan cara apapun.</li>
                  </ul>
                </li>
              </ol>
            </app-collapse-item>
            <app-collapse-item title="Tanggung Jawab TOQOO" class="box-shadow-0">
              <ol>
                <li>TOQOO akan berusaha semaksimal mungkin untuk mengelola dan merawat Platform TOQOO dan Layanan agar
                  tetap
                  lancar serta atas kebijakannya dapat memberikan Mitra Usaha rekomendasi perangkat keras dan
                  konfigurasi
                  jaringan yang sesuai dengan Platform TOQOO dan Layanan milik TOQOO. TOQOO akan memberikan bantuan
                  teknis
                  dan/atau operasional kepada Mitra Usaha dalam hal terjadi gangguan yang masih termasuk dalam batas
                  kewajaran sesuai dengan standar servis TOQOO, termasuk memberikan edukasi terkait penggunaan Platform
                  TOQOO dan Layanan kepada Mitra Usaha.</li>
                <li>TOQOO hanya memfasilitasi Mitra Usaha dalam menyediakan piranti lunak yang mendukung kegiatan
                  operasional usaha Mitra Usaha yang mencakup tetapi tidak terbatas pada penyediaan Layanan dan
                  pengelolaan
                  Toko.</li>
                <li>Mitra Usaha mengakui bahwa TOQOO dapat menggunakan jasa pihak ketiga untuk menyediakan perangkat
                  keras,
                  perangkat lunak, jaringan, sambungan, teknologi dan/atau jasa lainnya yang bertujuan untuk menunjang
                  penggunaan Platform TOQOO dan/atau Layanan namun apabila terdapat kelalaian atas jasa pihak ketiga,
                  TOQOO
                  tidak bertanggung jawab atas kerugian atau kerusakan yang diderita sebagai akibat dari segala tindakan
                  atau kelalaian pihak ketiga tersebut.</li>
                <li>Untuk membantu Mitra Usaha dalam hal penyediaan perangkat keras guna mendukung kegiatan operasional
                  Mitra Usaha, TOQOO dapat memberikan usulan dan penawaran perangkat keras kepada Mitra Usaha yang
                  diatur
                  dalam perjanjian terpisah tetapi menjadi satu kesatuan dalam perjanjian ini.</li>
                <li>Untuk membantu Mitra Usaha dalam hal penggunaan platform TOQOO, TOQOO bersedia untuk membantu Mitra
                  Usaha melakukan proses setting dan/atau pengaturan awal, saat pertama kali Mitra Usaha menggunakan
                  platform TOQOO.</li>
                <li>TOQOO dapat memberikan pelayanan kepada Mitra Usaha termasuk namun tidak terbatas pada pemberian
                  Mapping
                  SKU dengan didampingi oleh Mitra Usaha, sesi offline private training dan pelayanan lainnya yang
                  dianggap
                  perlu oleh TOQOO, dengan jangka waktu maksimal 5 (lima) hari kerja untuk masing-masing pelayanan, atau
                  jangka waktu yang akan diatur lebih lanjut sesuai dengan syarat dan ketentuan TOQOO.</li>
                <li>TOQOO dapat melakukan pengawasan dari waktu ke waktu untuk memastikan kelancaran penggunaan Platform
                  TOQOO guna memastikan kepatuhan terhadap Syarat dan Ketentuan Penggunaan ini dan/atau peraturan
                  perundang-undangan yang berlaku dan/atau putusan pengadilan dan/atau ketentuan lembaga administratif
                  atau
                  badan pemerintahan lainnya.</li>
                <li>TOQOO tidak bertanggung jawab atas setiap kerugian atau kerusakan yang disebabkan oleh setiap
                  kegagalan
                  atau kesalahan yang dilakukan oleh Mitra Pihak Ketiga Penyedia Layanan dan Penyedia Penawaran ataupun
                  kegagalan atau kesalahan Mitra Usaha dalam mematuhi Syarat dan Ketentuan Penggunaan TOQOO, Mitra Pihak
                  Ketiga Penyedia Layanan dan Penyedia Penawaran.</li>
                <li>TOQOO tidak bertanggung jawab atas keterlambatan, kegagalan pengiriman, kerusakan dan/atau atau
                  kerugian
                  yang disebabkan oleh termasuk namun tidak terbatas pada gangguan internet, berada di luar jangkauan,
                  dan/atau masalah komunikasi elektronik lainnya.</li>
                <li>TOQOO tidak mempunyai kewajiban apapun, termasuk untuk mengambil tindakan lebih jauh atau tindakan
                  hukum
                  yang dianggap perlu oleh Mitra Usaha, Mitra Pihak Ketiga Penyedia Layanan, atau Penyedia Penawaran,
                  terhadap setiap permasalahan atau perselisihan yang timbul antara Mitra Usaha dan Mitra Pihak Ketiga
                  Penyedia Layanan, atau Penyedia Penawaran, namun TOQOO akan berusaha untuk menjembatani Mitra Usaha
                  dan
                  Mitra Pihak Ketiga Penyedia Layanan atau Penyedia Penawaran dengan upaya wajar yang diperlukan dan
                  terhadap segala keputusan penyelesaian perselisihan tersebut tidak menimbulkan kewajiban apapun
                  terhadap
                  TOQOO.</li>
                <li>Dengan batas maksimal 30 (tiga puluh hari) kalender, Mitra Usaha dapat menerima uang garansi apabila
                  dapat dibuktikan terdapat permasalahan dalam sistem terkait dengan penggunaan platform TOQOO yang
                  tidak
                  dapat diselesaikan oleh TOQOO. (ketentuan ini tidak termasuk fitur tambahan yang diminta oleh Mitra
                  Usaha).</li>
              </ol>
            </app-collapse-item>
            <app-collapse-item title="Persaingan Usaha" class="box-shadow-0">
              <p>Tidak ada bagian dalam Syarat dan Ketentuan Penggunaan ini yang dimaksudkan untuk, atau dapat dianggap
                sebagai, pendirian persekutuan atau usaha bersama apapun antara Mitra Usaha dengan TOQOO, menjadikan
                Mitra
                Usaha sebagai bagian dan/atau perwakilan dari TOQOO, atau mengizinkan Mitra Usaha untuk membuat atau
                menyepakati apapun untuk dan/atau atas TOQOO. Mitra Usaha menyatakan bahwa Mitra Usaha bertindak untuk
                dan
                atas nama Mitra Usaha sendiri dan tidak untuk keuntungan orang lain.</p>
            </app-collapse-item>
            <app-collapse-item title="Hukum yang Berlaku" class="box-shadow-0">
              <p>Setiap referensi terhadap hukum dan peraturan yang berlaku berdasarkan Syarat dan Ketentuan ini harus
                ditafsirkan dan tunduk dengan ketentuan yang tercantum di bawah hukum dan peraturan Republik Indonesia.
                Segala permasalahan akan diselesaikan secara musyawarah terlebih dahulu, dimana pihak yang bersengketa
                sepakat untuk menyelesaikannya pada Pengadilan Negeri Jakarta Utara apabila dalam 30 (tiga puluh) hari
                sejak
                terjadinya musyawarah tidak menemukan kesepakatan (mufakat).</p>
            </app-collapse-item>
            <app-collapse-item title="Keadaan Kahar" class="box-shadow-0">
              <p>Keadaaan Kahar merupakan keadaan di luar kewenangan atau kontrol TOQOO, termasuk namun tidak terbatas
                pada
                bencana alam, penyebaran wabah penyakit, gangguan listrik, gangguan telekomunikasi, kebijakan
                pemerintah,
                dan juga jika perangkat lunak yang digunakan oleh Mitra Usaha mengalami gangguan yang diakibatkan oleh
                Bug
                atau virus, yang dapat menyebabkan terjadinya galat pada peralatan, sehingga program tidak berfungsi
                sebagaimana mestinya. Dalam hal tersebut Mitra Usaha setuju untuk membebaskan TOQOO dari setiap tuntutan
                dan
                tanggung jawab, jika TOQOO tidak dapat memfasilitasi Layanan.</p>
            </app-collapse-item>
            <app-collapse-item title="Lain-Lain" class="box-shadow-0">
              <ol>
                <li>Jika karena satu dan lain hal yang menyebabkan Syarat dan Ketentuan Penggunaan ini menjadi tidak
                  sah,
                  tidak berlaku, atau tidak dapat dilaksanakan berdasarkan hukum dari yurisdiksi manapun, maka
                  keabsahan,
                  keberlakuan atau pelaksanaan dari ketentuan lainnya maupun keabsahan, keberlakuan atau pelaksanaan
                  dari
                  ketentuan tersebut berdasarkan hukum dari yurisdiksi lain tidak akan menjadi terpengaruh atau
                  terganggu.
                </li>
                <li>TOQOO tidak menjanjikan adanya eksklufitas dan karenanya TOQOO memiliki hak untuk memberikan Layanan
                  kepada setiap pesaing Mitra Usaha. Apabila diperlukan, TOQOO juga memiliki hak untuk meminta dokumen
                  legalitas tambahan, surat pernyataan dan/atau dokumen pendukung lainnya yang berhubungan dengan Mitra
                  Usaha dan/atau kegiatan usaha Mitra Usaha.</li>
                <li>Mitra Usaha setuju dan sepakat bahwa segala perubahan, amandemen atas Syarat dan Ketentuan
                  Penggunaan
                  ini dapat dilakukan oleh TOQOO atas dasar pertimbangan TOQOO sendiri. Perubahan atas Syarat dan
                  Ketentuan
                  Penggunaan ini akan berlaku setelah TOQOO mengumumkan perubahan atas Syarat dan Ketentuan Penggunaan
                  tersebut melalui sarana apapun yang dipilih oleh TOQOO (termasuk dengan mengirimkan pemberitahuan
                  tertulis
                  secara langsung kepada Mitra Usaha). Dengan melanjutkan akses dan penggunaan Platform TOQOO dan/atau
                  Layanan setelah diterimanya pemberitahuan tersebut maka Mitra Usaha akan dianggap telah menyetujui
                  perubahan atau amandemen tersebut dan setuju untuk terikat dengan Syarat dan Ketentuan Penggunaan yang
                  sebagaimana telah diubah atau ditambahkan.</li>
                <li>Dengan tunduk pada ketentuan yang mengatur mengenai Kontrak Elektronik, Mitra Usaha setuju dan
                  sepakat
                  bahwa Syarat dan Ketentuan Penggunaan ini merupakan perjanjian dalam bentuk elektronik dan tindakan
                  Mitra
                  Usaha menekan tombol “sign up” pada saat pembukaan Akun atau tombol“sign in” pada saat akan mengakses
                  Akun
                  merupakan persetujuan aktif Mitra Usaha untuk mengikatkan diri dalam perjanjian dengan TOQOO sehingga
                  keberlakuan Syarat dan Ketentuan Penggunaan ini dan kebijakan privasi adalah sah dan mengikat secara
                  hukum
                  dan terus berlaku sepanjang penggunaan Platform TOQOO dan/atau Layanan oleh Mitra Usaha.</li>
                <li>Mitra Usaha setuju bahwa Mitra Usaha tidak akan memulai atau melakukan tuntutan atau keberatan
                  apapun
                  sehubungan dibuatnya maupun keabsahan Syarat dan Ketentuan Penggunaan ini dalam bentuk Kontrak
                  Elektronik.
                </li>
                <li>Bila Mitra Usaha tidak mematuhi atau melanggar ketentuan dalam Syarat dan Ketentuan Penggunaan ini,
                  dan
                  TOQOO tidak mengambil tindakan secara langsung, bukan berarti TOQOO mengesampingkan hak TOQOO untuk
                  mengambil tindakan yang diperlukan di kemudian hari.</li>
                <li>Syarat dan Ketentuan Penggunaan ini diatur berdasarkan hukum Republik Indonesia.</li>
                <li>Syarat dan Ketentuan Penggunaan ini merupakan satu kesatuan dan bagian yang tidak terpisahkan dari
                  Kesepakatan Bersama yang telah ditandatangai Para Pihak dan TOQOO User Guideline.</li>
                <li>Mitra Usaha telah membaca dan mengerti seluruh Syarat dan Ketentuan Penggunaan ini dan
                  konsekuensinya
                  dan dengan ini menerima setiap hak, kewajiban, dan ketentuan yang diatur di dalamnya.</li>
              </ol>
            </app-collapse-item>
          </app-collapse>
          <p>Mitra Usaha telah membaca dan mengerti seluruh Ketentuan Penggunaan ini dan konsekuensinya dan dengan ini
            menerima setiap hak, kewajiban, dan ketentuan yang diatur di dalamnya.</p>

          <div class="d-flex justify-content-end">
            <b-button variant="primary" :disabled="disabledTerms" @click="handleAggrementTerms">
              Setuju
            </b-button>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal id="modal-agreement" hide-footer body-class="p-0" ok-only scrollable ok-title="Accept" centered size="lg"
      title="Kesepakatan Bersama">
      <div id="scroll-terms" class="scroll-terms" @scroll="handleOnScrollAgreement">
        <div class="px-2 py-1">
          <!-- <h5 class="font-weight-bold">Kesepakatan</h5>
          <h6 class="font-weight-bold">Mulai dari 02 Febuari 2023</h6>
          <hr> -->
          <p>Pihak Pertama (PT. Dayacipta Solusi Indonesia) dan Pihak Kedua (Mitra Usaha) secara bersama-sama dalam
            Kesepakatan Bersama ini disebut Para Pihak. Para Pihak menerangkan terlebih dahulu hal-hal sebagai berikut:
          </p>
          <ol>
            <li>Bahwa Pihak Pertama adalah suatu perusahaan yang bergerak di bidang aktivitas pengolahan data, portal
              web dan/atau platform digital dengan tujuan komersial serta penyediaan layanan aplikasi sistem kasir dan
              pengelolaan stok dengan merek dagang “TOQOO” yang selanjutnya akan disebut sebagai TOQOO.</li>
            <li>Bahwa Pihak Kedua adalah suatu badan hukum dan/atau perorangan yang bergerak di bidang perdagangan
              barang dan/atau jasa, bertindak secara independen, menjalankan bisnisnya dengan kebijakan sendiri dan
              bermaksud untuk menggunakan layanan TOQOO.</li>
            <li>Bahwa Pihak Pertama bersedia memberikan layanan TOQOO kepada Pihak Kedua.</li>
          </ol>
          <p>Sehubungan dengan hal-hal tersebut diatas, maka Para Pihak setuju dan sepakat untuk mengadakan Kesepakatan
            Bersama ini dengan syarat-syarat dan ketentuan sebagai berikut:</p>
          <p class="text-center">Pasal 1</p>
          <p>Para Pihak sepakat dan menyetujui bahwa Harga Berlangganan untuk 2 user id aplikasi TOQOO adalah :</p>
          <ul>
            <li>Rp. 1.050.000,- / 3 bulan / cabang</li>
            <li>Rp. 2.100.000,- / 6 bulan / cabang</li>
            <li>Rp. 4.200.000,- / 1 tahun / cabang</li>
          </ul>
          <p>Dan untuk penambahan User id :</p>
          <ul>
            <li>Rp. 25.000,- / bulan / user id.</li>
          </ul>
          <p>Penambahan toko cabang diwajibkan untuk pembuatan user baru dengan memilih paket layanan yang tersedia.</p>
          <p>Harga Berlangganan tersebut diatas dapat berubah sewaktu-waktu berdasarkan kebijakan, syarat dan ketentuan
            dari Pihak Pertama.</p>
          <p>Pembayaran dilakukan melalui transfer melalui :<br>No. Rek : 065-098-8981<br>Bank : BCA<br>Atas Nama : PT
            Dayacipta Solusi Indonesia</p>
          <p>Masa Aktif Seluruh layanan TOQOO (tidak terbatas aplikasi) dapat digunakan setelah pelanggan melakukan
            pembayaran paling lambat 3 (tiga) hari kalender setelah pembayaran diterima oleh Pihak Pertama.</p>

          <p class="text-center">Pasal 2</p>
          <ol>
            <li>Kesepakatan Bersama ini merupakan satu kesatuan dan bagian yang tidak perpisahkan dari Syarat dan
              Ketentuan Penggunaan TOQOO.</li>
            <li>Dengan menandatangani Kesepakatan Bersama ini Para Pihak sepakat akan tunduk terhadap segala ketentuan
              yang terdapat dalam Syarat dan Ketentuan Penggunaan TOQOO.</li>
            <li>Kesepakatan Bersama ini berlaku efektif sejak ditandatanganinya Kesepakatan Bersama ini.</li>
          </ol>
          <p>Mitra Usaha telah membaca dan mengerti seluruh Kesepakatan Bersama ini dan konsekuensinya dan dengan ini
            menerima setiap hak, kewajiban, dan ketentuan yang diatur di dalamnya.</p>

          <div class="d-flex justify-content-end">
            <b-button variant="primary" :disabled="disabledAgreement" @click="handleAggrement">
              Setuju
            </b-button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormCheckbox, BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroup, BCardText, BCardTitle, BForm, BButton, BSpinner, BInputGroupAppend, VBModal, BModal, BFormSelect, BFormSelectOption
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'

import {
  LMap, LTileLayer, LMarker, LPopup, LTooltip
} from 'vue2-leaflet'

import VGeosearch from 'vue2-leaflet-geosearch';
import { OpenStreetMapProvider } from 'leaflet-geosearch';

import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

import { country_codes } from '@/libs/country-codes'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BCardText,
    BCardTitle,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    BInputGroupAppend,
    BFormCheckbox,
    BFormSelect,
    BFormSelectOption,

    // Map
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    VGeosearch,
    LTooltip,

    BModal,
    VBModal,
    AppCollapse,
    AppCollapseItem,
  },
  directives: {
    'b-modal': VBModal,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      countryCodes: country_codes,
      selectedCountryCode: '+62',
      status: '',
      password: '',
      userEmail: '',
      terms_condition: false,
      agreement: false,
      sideImg: require('@/assets/images/background.png'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      isLoading: false,
      handphone: '',
      formPayload: {
        operator_phone: '',
        operator_name: '',
        merchant_name: '',
        password: '',
        referral_code: ''
      },
      defaultLocation: {
        lat: 8.9806,
        lng: 38.7578
      },
      // Map
      loading: false,
      geosearchOptions: { // Important part Here
        provider: new OpenStreetMapProvider(),
        autoClose: true,
        keepResult: true,
        showMarker: false,
      },
      tileProvider: {
        attribution:
          '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      },
      zoom: 18,
      userLocation: {},
      position: {},
      address: "",
      dragging: false,
      // End Map
      disabledTerms: true,
      disabledAgreement: true
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    tooltipContent() {
      if (this.dragging) return "...";
      if (this.loading) return "Loading...";
      return `<strong>${this.address.replace(
        ",",
        "<br/>"
      )}</strong> <hr/><strong>lat:</strong> ${this.position.lat
        }<br/> <strong>lng:</strong> ${this.position.lng}`;
    }
  },
  watch: {
    handphone(val) {
      let phone = val
      if (val.startsWith('0')) {
        phone = val.substring(1);
      }
      this.formPayload.operator_phone = `${this.selectedCountryCode.replace(/^\+/, '')}${phone}`;
    },
    position: {
      deep: true,
      async handler(value) {
        this.address = await this.getAddress();
        this.formPayload.address_latitude = value.lat
        this.formPayload.address_longitude = value.lng
      }
    }
  },
  mounted() {
    this.getUserPosition();
    this.$refs.map.mapObject.on("geosearch/showlocation", this.onSearch);
  },
  methods: {
    removeLeadingZero() {
      if (this.handphone !== null) {
        let value = this.handphone.toString();
        if (value.startsWith('0') && value.length > 1) {
          this.handphone = parseInt(value, 10);
          // this.prepareFormData(this.userEmail, this.password)
        }
      }
    },
    handleAggrementTerms() {
      this.terms_condition = true
      this.$bvModal.hide('modal-terms-and-condition')
    },
    handleAggrement() {
      this.agreement = true
      this.$bvModal.hide('modal-agreement')
    },
    handleOnScrollAgreement(event) {
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      const isBottomReached = scrollTop + clientHeight + 1 >= scrollHeight;

      if (isBottomReached) {
        this.disabledAgreement = false
      }
    },
    handleOnScrollTerms(event) {
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      const isBottomReached = scrollTop + clientHeight + 1 >= scrollHeight;

      if (isBottomReached) {
        this.disabledTerms = false
      }
    },
    async getAddress() {
      this.loading = true;
      let address = "Unresolved address";
      try {
        const { lat, lng } = this.position;
        const result = await fetch(
          `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lng}`
        );
        if (result.status === 200) {
          const body = await result.json();
          address = body.display_name;
        }
      } catch (e) {
        console.error("Reverse Geocode Error->", e);
      }
      this.loading = false;
      return address;
    },
    async onMapClick(value) {
      // place the marker on the clicked spot
      this.position = value.latlng;
    },
    onSearch(value) {
      const loc = value.location;
      this.position = { lat: loc.y, lng: loc.x };
    },
    async getUserPosition() {
      if (navigator.geolocation) {
        // get GPS position
        navigator.geolocation.getCurrentPosition(pos => {
          // set the user location
          this.userLocation = {
            lat: pos.coords.latitude,
            lng: pos.coords.longitude
          };
        });
      }
    },
    async validationForm() {
      this.$refs.loginValidation.validate().then(async success => {
        this.isLoading = true
        if (success) {
          try {
            const response = await this.$http.post('/register', this.formPayload)
            const userData = JSON.stringify(response.data.data.user)
            const userPermission = JSON.stringify(response.data.data.permission)

            useJwt.setToken(response.data.data.token)

            localStorage.setItem('userPermission', userPermission)
            localStorage.setItem('userData', userData)
            localStorage.setItem('temporary_token', response.data.data.token)

            localStorage.setItem('operator_phone', this.formPayload.operator_phone)
            localStorage.setItem('otp_type', 'register')
            localStorage.setItem('expired_at', response.data.data.expired_at)
            // useJwt.setToken(response.data.data.token)
            this.$router.push('/otp')
            this.isLoading = false
          } catch (error) {
            this.isLoading = false
          }
        } else {
          this.isLoading = false
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.scroll-terms {
  max-height: calc(100vh - 20rem);
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #fff;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #aaa;
    border-radius: 4px;
  }
}
</style>

<style lang="scss" scoped>
.image__cover--login {
  border-radius: 0px 32px 32px 0px;
  object-fit: cover;
  height: 100vh;
}

.left--side__wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: rgba(21, 38, 61, 0.2);
  backdrop-filter: blur(44.3121px);
  border-radius: 20.257px;
  width: 420px;

  .wrapper__side {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h1 {
      font-family: 'Medula One', sans-serif !important;
      font-size: 50px;
    }

    h5,
    h6 {
      color: #fff;
      font-family: 'Be Vietnam Pro', sans-serif !important;
    }
  }
}
</style>
